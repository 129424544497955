import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";

import "swiper/css/grid";

import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";

const SliderOne = ({ sliderConf, items, ItemComponent }) => {
  return (
    <Swiper {...sliderConf} className="slider-one">
      {items.map((item, key) => (
        <SwiperSlide key={key}>
          <ItemComponent key={key} item={item} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default SliderOne;
