import React from "react";
import RichText from "../../../../components/atoms/RichText/RichText";
import WordChange from "words-change-react";
import getTextFromRichText from "components/helpers/getTextFromRichText";

const SeoOne = ({ content }) => {
  const keywords = getTextFromRichText(content.primary.keywords).split(",");
  return (
    <>
      <div className="seo-1">
        <div className="seo-1-strong">
          <strong id="test" className="h3 seo-1-strong-title">
            <WordChange list={keywords} />
          </strong>
          <div className="seo-1-strong-corner-top"></div>
          <div className="seo-1-strong-corner-bottom"></div>
        </div>
        <div className="text">
          <RichText content={content.primary.keywords2} />
        </div>
      </div>
      <div id="seoData" style={{ display: "none" }}>
        <RichText type="text" content={content.primary.keywords} />
      </div>
    </>
  );
};

export default SeoOne;
