import React from "react";
import RichText from "../../../../components/atoms/RichText/RichText";

const BigTitle = ({ content }) => {
  return (
    <div className="slice slice-full big-title">
      <div className="big-title-title">
        <RichText content={content.primary.title} />
      </div>
      <div className="big-title-subtitle">
        <RichText content={content.primary.subtitle} />
      </div>
    </div>
  );
};

export default BigTitle;
