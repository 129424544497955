import React, { useContext } from "react";
import Btn from "components/atoms/Btn/Btn";
import RichText from "components/atoms/RichText/RichText";
import resolveUrl from "components/helpers/resolveUrl";
import { LayoutContext } from "layout/GenericLayout";

const LinkButton = ({ content }) => {
  const {
    btn_type: appearance,
    btn_label: label,
    btn_link: link,
    margin_top,
    margin_bottom,
  } = content.primary;
  const { routes } = useContext(LayoutContext);
  const url = resolveUrl(link, routes);
  return (
    <div
      className="link-button"
      style={{ marginTop: margin_top, marginBottom: margin_bottom }}
    >
      <Btn to={url} appearance={appearance}>
        <RichText type="text" content={label} />
      </Btn>
    </div>
  );
};

export default LinkButton;
