import { EffectFade, Navigation, Pagination, Autoplay } from "swiper";

export default {
  spaceBetween: 0,
  slidesPerView: 1,
  effect: "fade",
  navigation: true,
  speed: 1000,
  loop: true,
  autoplay: {
    delay: 3000,
    disableOnInteraction: false,
  },
  pagination: {
    clickable: true,
  },
  modules: [EffectFade, Navigation, Pagination, Autoplay],
};
