import React from "react";
import RichText from "components/atoms/RichText/RichText";
import classNames from "classnames";

const AccordionItem = ({ item }) => {
  const [active, setActive] = React.useState(false);
  const [maxHeight, setMaxHeight] = React.useState(0);

  const handleClick = () => {
    setActive(!active);
  };

  const contentRef = React.useRef(null);

  React.useEffect(() => {
    setMaxHeight(active ? `${contentRef.current.scrollHeight}px` : 0);
  }, [active]);

  const tabClass = classNames({
    "accordion-tab": true,
    active: active,
  });

  const contentStyles = {
    maxHeight: maxHeight,
  };

  return (
    <div className={tabClass}>
      <div onClick={handleClick} className="accordion-label">
        <RichText type="text" content={item.label} />
      </div>
      <div
        className="accordion-content"
        style={{ ...contentStyles }}
        ref={contentRef}
      >
        <div className="accordion-content__inner">
          <blockquote>
            <RichText content={item.content} />
          </blockquote>
        </div>
      </div>
    </div>
  );
};

const Accordion = ({ content }) => {
  return (
    <div className="slice accordion">
      {content.items.map((item, key) => {
        return <AccordionItem key={key} item={item} />;
      })}
    </div>
  );
};

export default Accordion;
