import React from "react";
import RichText from "../../../../components/atoms/RichText/RichText";
import Btn from "../../../../components/atoms/Btn/Btn";

const ShortBiography = ({ content }) => {
  return (
    <div className="short-biography">
      <div className="short-biography-container">
        <div className="short-biography-content">
          <div className="short-biography-content-img">
            <picture>
              <img
                src={content.primary.image1.url}
                alt="Photographe Pascale Pyot"
              />
            </picture>
          </div>
          <span>
            <RichText content={content.primary.title} />
          </span>
          <RichText content={content.primary.text} />
          <div className="btn-ctn">
            <Btn to={content.primary.link.uid} appearance="secondary">
              <RichText type="text" content={content.primary.label} />
            </Btn>
          </div>
        </div>
        <div className="short-biography-cite">
          <cite className="cite font-secondary-light">
            <RichText content={content.primary.cite} />
          </cite>
          <div className="short-biography-cite-img">
            <img src={content.primary.image2.url} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShortBiography;
