import loaders from "./Prismic/loaders";
import resolvers from "./Prismic/resolvers";

export default {
  getByType: async type => {
    const pageData = await loaders.loadById(type);
    const pageResult = resolvers[type](pageData.data);
    return { pageData: pageResult };
  },
  getByUID: async (type, uid) => {
    const pageData = await loaders.loadByUid(type, uid);
    const pageResult = resolvers[type](pageData.data);
    return { pageData: pageResult };
  },
};
