import React from "react";
import RichText from "components/atoms/RichText/RichText";

const RichTextSlice = ({ content }) => {
  const text = content.primary.content;
  return (
    <div className="rich-text slice">
      <RichText content={text} />
    </div>
  );
};

export default RichTextSlice;
