import React from "react";
import RichText from "../../../../components/atoms/RichText/RichText";

const Cite = ({ content }) => {
  return (
    <div className="cite-block">
      <div className="cite-block-content">
        <cite className="cite-block-content-text cite font-secondary-light">
          <RichText type="text" content={content.primary.cite} />
        </cite>
        <p className="cite-block-content-author">
          <RichText type="text" content={content.primary.author} />
        </p>
      </div>
    </div>
  );
};

export default Cite;
