import React, { createContext } from "react";

const LayoutContext = createContext();

const LayoutProvider = LayoutContext.Provider;

const GenericLayout = ({ header, footer, children, layoutData }) => {
  const headerHeight = 135;

  layoutData.headerHeight = headerHeight;
  return (
    <>
      <LayoutProvider value={layoutData}>
        <div className="generic-layout">
          {header}
          <div className={`generic-layout__content`}>{children}</div>
          {footer}
        </div>
      </LayoutProvider>
    </>
  );
};

export default GenericLayout;

export { LayoutContext };
