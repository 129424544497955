import React, { useContext } from "react";
import * as prismicH from "@prismicio/helpers";
import parse from "html-react-parser";
import { LayoutContext } from "layout/GenericLayout";
import resolveUrl from "components/helpers/resolveUrl";

const RichText = ({ content, type = "html" }) => {
  function linkResolver(doc) {
    const { routes } = useContext(LayoutContext);
    const url = resolveUrl(doc, routes);
    return url;
  }
  return (
    <>
      {type === "text" ? (
        parse(prismicH.asText(content))
      ) : (
        <div className="rich-text">
          {parse(prismicH.asHTML(content, linkResolver))}
        </div>
      )}
    </>
  );
};

export default RichText;
