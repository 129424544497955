import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import RichText from "components/atoms/RichText/RichText";
import { LayoutContext } from "layout/GenericLayout";
import resolveUrl from "components/helpers/resolveUrl";

const NavItem = ({ navs, item }) => {
  const [submenuOpen, setSubmenuOpen] = React.useState(false);

  const { loading, routes } = useContext(LayoutContext);

  const handleMouseOver = () => {
    setSubmenuOpen(true);
  };
  const handleMouseOut = () => {
    setSubmenuOpen(false);
  };

  useEffect(() => {
    if (loading) {
      setSubmenuOpen(false);
    }
  }, [loading]);

  const url = resolveUrl(item.link, routes);

  return (
    <li
      className="navigator-item"
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      <Link className="navigator-item-link" to={url}>
        <span className="navigator-item-link__label">
          <RichText type="text" content={item.label} />
        </span>
      </Link>
      {item.submenu === true ? (
        <div
          className={`navigator-submenu submenu${submenuOpen ? " open" : ""}`}
        >
          {navs[item.submenu_doc.uid].map((subitem, key) => {
            const url = resolveUrl(subitem.link, routes);
            return (
              <div className="navigator-submenu-item" key={key}>
                <Link
                  className="navigator-submenu-item-link"
                  to={`${item.link.uid}/${subitem.link.uid}`}
                >
                  <div className="navigator-submenu-item-img">
                    <img src={subitem.image.url} alt="test" />
                  </div>
                  <div className="navigator-submenu-item-content">
                    <span className="navigator-submenu-item-content__label">
                      <RichText type="text" content={subitem.label} />
                    </span>
                    <p className="navigator-submenu-item-content__description">
                      <RichText type="text" content={subitem.description} />
                    </p>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      ) : (
        ""
      )}
    </li>
  );
};

const Nav = ({ navs }) => {
  const mainNav = navs?.main_nav;

  return (
    mainNav && (
      <nav className="navigator">
        <ul className="navigator-container">
          {mainNav.map((item, key) => {
            return <NavItem key={key} item={item} navs={navs} />;
          })}
        </ul>
      </nav>
    )
  );
};

export default Nav;
