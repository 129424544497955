import getters from "./getters";

export default {
  homepage: (data) => {
    const meta = getters.getMeta(data);
    const slices = data.body;
    return { ...meta, slices };
  },
  legales: (data) => {
    const meta = getters.getMeta(data);
    const title = data.title;
    const subtitle = data.subtitle;
    return { ...meta, title, subtitle };
  },
  legal: (data) => {
    const meta = getters.getMeta(data);
    const title = data.title;
    const subtitle = data.subtitle;
    const content = data.content;
    return { ...meta, title, subtitle, content };
  },
  apropos: (data) => {
    const meta = getters.getMeta(data);
    const content = {
      title: data.title,
      subtitle: data.subtitle,
    };

    const slices = data.body;
    return { ...meta, slices, content };
  },
  contact: (data) => {
    const meta = getters.getMeta(data);
    const content = {
      introText: data.intro_text,
      infoContact: data.info_contact,
      title: data.title,
      description: data.description,
    };
    return { ...meta, content };
  },
  supportsphoto: (data) => {
    const meta = getters.getMeta(data);
    const content = {
      title: data.title,
      subtitle: data.subtitle,
    };

    const slices = data.body;
    return { ...meta, slices, content };
  },
  prestations: (data) => {
    const meta = getters.getMeta(data);
    const content = {
      title: data.title,
      subtitle: data.subtitle,
    };
    return { ...meta, content };
  },
  prestation: (data) => {
    const meta = getters.getMeta(data);
    const slices = data.body;
    return { ...meta, slices };
  },
  portfolios: (data) => {
    const meta = getters.getMeta(data);
    const content = {
      title: data.title,
      subtitle: data.subtitle,
    };
    return { ...meta, content };
  },
  portfolio: (data) => {
    const meta = getters.getMeta(data);
    const content = {
      title: data.title,
      subtitle: data.subtitle,
    };

    const slices = data.body;
    return { ...meta, slices, content };
  },
  custom_page: (data) => {
    const meta = getters.getMeta(data);
    const slices = data.body;
    return { ...meta, slices };
  },

  getNavs: (navs) => {
    const result = {};
    navs.map((nav) => {
      result[nav.uid] = nav.data.items;
    });
    return result;
  },
  getUrlsFromNavs: (navs) => {
    const result = {};
    for (let key in navs) {
      navs[key].map((item) => {
        result[item.link.type] = {};
        result[item.link.type].url = item.link.uid;
      });
    }
    return result;
  },
  getUrlsFromRoutesData: (routesData) => {
    const result = {};
    routesData.map((route) => {
      result[route.document.type] = {};
      result[route.document.type].url = route.document.uid;
    });
    return result;
  },
};
