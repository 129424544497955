import React from "react";
import slices from "./slices/slices";

const Error = () => {
  return "component not found";
};

const Slice = ({ content, routes, ident }) => {
  const Component = slices[content.slice_type]
    ? slices[content.slice_type].Slice
    : Error;
  return <Component content={content} ident={ident} routes={routes} />;
};

const SlicesZone = ({ slices, routes }) => {
  return (
    <>
      {slices.map((slice, key) => {
        return <Slice key={key} ident={key} content={slice} routes={routes} />;
      })}
    </>
  );
};

export default SlicesZone;
