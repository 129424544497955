import React from "react";
import { useLightgallery } from "react-lightgallery";
import logo from "assets/img/logo.png";

const SimpleImage = ({ item, ident }) => {
  const { openGallery } = useLightgallery();

  return (
    <div
      onClick={() => {
        openGallery("one", ident);
      }}
    >
      <div className="image-card">
        <img src={item.image.card?.url} alt={item.image.alt} loading="lazy" />
        <div className="image-card__logo">
          <div className="image-card__logo__inner">
            <img src={logo} alt="" className="image-card__logo__img" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SimpleImage;
