import React from "react";
import ImageCard from "./ImageCard";
import SimpleImage from "./SimpleImage";
import { LightgalleryProvider } from "react-lightgallery";
import "lightgallery.js/dist/css/lightgallery.css";
import Btn from "components/atoms/Btn/Btn";

const PortfolioImages = ({ content }) => {
  const images = content.items;
  const imagesPerPage = 6;
  const [imagesToShow, setImagesToShow] = React.useState(
    images.slice(0, imagesPerPage)
  );

  const [showedImages, setShowedImages] = React.useState(imagesPerPage);

  React.useEffect(() => {
    setImagesToShow(images.slice(0, showedImages));
  }, [showedImages]);

  const showMoreImages = () => {
    setShowedImages(showedImages + imagesPerPage);
  };

  return (
    <>
      <LightgalleryProvider
        group="one"
        lightgallerySettings={{
          // settings: https://sachinchoolur.github.io/lightgallery.js/docs/api.html
          // https://github.com/VLZH/react-lightgallery
          download: false,
        }}
      >
        <div className="portfolio-images__hidden" style={{ display: "none" }}>
          {images.map((item, key) => (
            <div key={key}>
              <ImageCard ident={key} group="one" item={item} />
            </div>
          ))}
        </div>

        <div className="portfolio-images slice">
          {imagesToShow.map((item, key) => (
            <div key={key}>
              <SimpleImage ident={key} item={item} />
            </div>
          ))}
        </div>
        {imagesToShow.length < images.length && (
          <div className="portfolio-images__show-more">
            <Btn appearance="secondary" onClick={showMoreImages}>
              Afficher plus d'images
            </Btn>
          </div>
        )}
      </LightgalleryProvider>
    </>
  );
};

export default PortfolioImages;
