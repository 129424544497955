import React from "react";
import SliderOne from "components/organisms/SliderOne/SliderOne";
import sliderConf from "./sliderConf";
import Slide from "./Slide";

const SliderBig = ({ content }) => {
  const { items } = content;
  return (
    <div className="slice slice-full slider-big">
      <div className="slider-big-container">
        <SliderOne
          sliderConf={sliderConf}
          items={items}
          ItemComponent={Slide}
        />
      </div>
    </div>
  );
};

export default SliderBig;
