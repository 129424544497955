import { Pagination, Navigation, Autoplay } from "swiper";

export default {
  spaceBetween: 0,
  loop: true,
  speed: 1000,
  autoplay: {
    delay: 3000,
    disableOnInteraction: false,
  },
  // navigation: true,
  pagination: {
    clickable: true,
  },
  modules: [Pagination, Navigation, Autoplay],
};
