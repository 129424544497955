import React from "react";
import SliderOne from "components/organisms/SliderOne/SliderOne";
import sliderConf from "./sliderConf";
import { LightgalleryProvider, LightgalleryItem } from "react-lightgallery";
import "lightgallery.js/dist/css/lightgallery.css";
import {
  isMobile,
  isTablet,
} from "components/helpers/mediaQueries/mediaQueries";
import classNames from "classnames";
import RichText from "components/atoms/RichText/RichText";

const Card = ({ item }) => {
  const classes = classNames({
    "images-grid__item": true,
    mobile: isMobile,
    tablet: isTablet,
  });
  return (
    <>
      <div className={classes}>
        {item.map((img, key) => {
          const { image } = img;
          // card.url = card.url.replace('auto=compress,format', 'q=100')
          // card.url="hol"
          for (const property in image) {
            switch (property) {
              case "card":
                image.card.url = image.card.url + "&q=100&fm=webp";
              case "cardBig":
                image.cardBig.url = image.cardBig.url + "&q=100&fm=webp";
            }
          }
          return (
            <LightgalleryItem
              group="any"
              className="photo-card"
              src={image.xxl ? image.xxl?.url : image.url}
              ident={key}
              key={key}
              thumb={image.thumb?.url}
            >
              <div className="images-grid__item__image" data-src={image.url}>
                <picture>
                  <source
                    srcSet={image.card?.url}
                    media="(min-width: 1018px)"
                  />
                  <source
                    srcSet={image.cardBig?.url}
                    media="(min-width: 600px)"
                  />
                  <img src={image.card?.url} alt={image.alt} loading="lazy" />
                </picture>
              </div>
            </LightgalleryItem>
          );
        })}
      </div>
    </>
  );
};

const ImagesGrid = ({ content }) => {
  const { items: itemsToOrder } = content;

  const resolveItems = (qty) => {
    const arr = [];
    const quantity = Math.ceil(itemsToOrder.length / qty);
    for (let i = 0; i < quantity; i++) {
      arr.push(itemsToOrder.slice(i * qty, (i + 1) * qty));
    }
    return arr;
  };
  const items = resolveItems(isMobile ? 4 : isTablet ? 4 : 8);

  const {
    grid_title: title,
    show_title: showTitle,
    autoplay,
    delay,
  } = content.primary;

  const itemID = content.id.replace("$", "");

  const autoplayOptions = {
    delay: delay && delay >= 500 ? delay : 3000,
    disableOnInteraction: false,
  };
  return (
    <div id="lightgallery" className="slice images-grid">
      {title && showTitle ? (
        <div className="images-grid__title">
          <h3 className="h3">
            <RichText type="text" content={title} />
          </h3>
        </div>
      ) : null}

      <div className="images-grid__container">
        <div
          id={`${itemID}-prev`}
          className="images-grid__button images-grid__button-prev"
        >
          <i className="icon icon-thin-arrow"></i>
        </div>
        <LightgalleryProvider
          lightgallerySettings={{
            download: false,
          }}
        >
          <div className="images-grid-item">
            <SliderOne
              sliderConf={{
                ...sliderConf,
                navigation: {
                  prevEl: `#${itemID}-prev`,
                  nextEl: `#${itemID}-next`,
                },
                autoplay:
                  autoplay && autoplay === true ? autoplayOptions : false,
              }}
              items={items}
              ItemComponent={Card}
            />
          </div>
        </LightgalleryProvider>
        <div
          id={`${itemID}-next`}
          className="images-grid__button images-grid__button-next"
        >
          <i className="icon icon-thin-arrow"></i>
        </div>
      </div>
    </div>
  );
};

export default ImagesGrid;
