import React from "react";
import { LightgalleryItem } from "react-lightgallery";

const ImageCard = ({ item, ident }) => {
  const { image } = item;
  return (
    <div className="image-card">
      <LightgalleryItem
        group="one"
        src={image.url}
        thumb={image.thumb?.url}
        subHtml={`#subHtml-${ident}`}
      >
        <img src={image.card?.url} alt={image.alt} loading="lazy" />
      </LightgalleryItem>
      <div id={`subHtml-${ident}`}>{image.alt}</div>
    </div>
  );
};

export default ImageCard;
