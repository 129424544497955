import React, { useContext } from "react";
import Nav from "./Nav";
import { memo } from "react";
import RichText from "components/atoms/RichText/RichText";
import * as prismicH from "@prismicio/helpers";
import Btn from "components/atoms/Btn/Btn";
import resolveUrl from "components/helpers/resolveUrl";
import { LayoutContext } from "layout/GenericLayout";

const Footer = ({ nav, footerData }) => {
  if (!footerData) return;

  const {
    title,
    subtitle,
    content,
    image,
    nav_title,
    bottom_nav,
    copyrights,
    cite,
    phone,
    email,
  } = footerData;

  const cities = prismicH.asText(footerData.cities).split(",");
  const { routes } = useContext(LayoutContext);

  return (
    <footer className="main-footer">
      <div className="main-footer__content">
        <div className="main-footer__about">
          <h2 className="h3 main-footer__about__title">
            <RichText type="text" content={title} />
          </h2>
          {/* <h3 className="h4 main-footer__about__subtitle">
            <RichText type="text" content={subtitle} />
          </h3> */}
          <div className="main-footer__about__text">
            <RichText content={content} />
          </div>
          <div className="main-footer__about__tags">
            {cities.map((city, key) => (
              <strong key={key} className="main-footer__about__tag">
                {city.trim()}
              </strong>
            ))}
          </div>
        </div>
        <div className="main-footer__content__separator"></div>
        <div className="main-footer__image-social">
          <div className="main-footer__image-social__img">
            <img src={image.url} alt={image.alt} />
          </div>
          <div className="main-footer__image-social__social">
            <a
              href="https://www.facebook.com/PascalePyotPhotographie/"
              target="_blank"
              aria-label="Suivre Pascale Pyot photographe Bressuire en Facebook"
            >
              <i className="icon icon-facebook"></i>
            </a>
            <a
              href="https://www.instagram.com/pascale.pyot/"
              target="_blank"
              aria-label="Suivre Pascale Pyot photographe Bressuire en Instagram"
            >
              <i
                className="icon icon-instagram
              "
              ></i>
            </a>
          </div>
        </div>
        <div className="main-footer__content__separator"></div>
        <div className="main-footer__nav">
          <h2 className="h3 main-footer__nav__title">
            <RichText type="text" content={nav_title} />
          </h2>
          <Nav nav={nav} />
        </div>
      </div>
      <div className="main-footer__seo">
        {bottom_nav &&
          bottom_nav.map((item, key) => {
            const url = resolveUrl(item.btn_link, routes);
            return (
              <Btn key={key} to={url} classes="main-footer__seo__tag">
                <RichText type="text" content={item.btn_label} />
              </Btn>
            );
          })}
      </div>
      <div className="main-footer__info">
        <div className="main-footer__info__copy">
          <RichText type="text" content={copyrights} />
        </div>
        <div className="main-footer__info__cite">
          <RichText type="text" content={cite} />
        </div>
        <div className="main-footer__info__contact">
          <p>{phone}</p>
          <p>
            <RichText type="text" content={email} />
          </p>
        </div>
      </div>
    </footer>
  );
};

export default memo(Footer);
