import React from "react";
import RichText from "components/atoms/RichText/RichText";

const resolveData = data => {
  const title = data.primary.image_title;
  const description = data.primary.image_description;
  const images = data.items.map(item => item.image);

  return { title, description, images };
};

const ImageWithTitleAndDescription = ({ content }) => {
  const { title, description, images } = resolveData(content);

  return (
    <div className="image-with-title-and-description slice">
      <div className="image-with-title-and-description__title">
        <RichText content={title} />
      </div>
      {images[0].url && (
        <div className="image-with-title-and-description__images">
          {images.map((image, key) => {
            return (
              <div
                key={key}
                className="image-with-title-and-description__images__img"
              >
                <img src={image.url} alt={image.alt} />
              </div>
            );
          })}
        </div>
      )}
      <div className="image-with-title-and-description__description">
        <RichText content={description} />
      </div>
    </div>
  );
};

export default ImageWithTitleAndDescription;
