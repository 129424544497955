import React from "react";
import Nav from "layout/Header/Nav/Nav";
import MobileNav from "./MobileNav/MobileNav";
import logo from "assets/img/logo.png";
import { Link } from "react-router-dom";
import TopHeader from "components/molecules/TopHeader";

const Header = ({ navs }) => {
  const [isNavOpen, setIsNavOpen] = React.useState(false);
  const handleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  const navIcon = !isNavOpen ? "menu" : "close";
  return (
    <>
      <header id="mainHeader" className="main-header">
        {/* <TopHeader /> */}
        <div className="main-header-content">
          <div className="main-header__nav-ctn">
            <Nav navs={navs} />
            <div className="main-header__social">
              <a
                className="main-header__social__link"
                href="https://www.facebook.com/PascalePyotPhotographie/"
                target="_blank"
                aria-label="Suivre Pascale Pyot photographe Bressuire en Facebook"
              >
                <i className="icon icon-facebook_header"></i>
              </a>
              <a
                className="main-header__social__link"
                href="https://www.instagram.com/pascale.pyot/"
                target="_blank"
                aria-label="Suivre Pascale Pyot photographe Bressuire en Instagram"
              >
                <i className="icon icon-instagram_header"></i>
              </a>
            </div>
          </div>
          <div className="main-header-logo">
            <Link
              to="/"
              onClick={() => {
                setIsNavOpen(false);
              }}
            >
              <img
                className="main-header-logo-img"
                src={logo}
                alt="Pascale Pyot photographe logo"
              />
            </Link>

            <span className="main-header__account">
              <a
                href={process.env.USER_AREA_URL}
                aria-label="Espace utilisateur Pascale Pyot photographe Bressuire"
              >
                <i className="icon icon-user"></i>
              </a>
            </span>
          </div>
          <div className="main-header-mobile-btn" onClick={handleNav}>
            <span id="mobileBtnIcon" className={`icon icon-${navIcon}`}></span>
          </div>
        </div>
        <MobileNav navs={navs} isOpen={isNavOpen} handleNav={handleNav} />
      </header>
    </>
  );
};

export default Header;
