import React from "react";
import RichText from "components/atoms/RichText/RichText";

const TextWithFloatingImage = ({ content }) => {
  const image = content.primary.image;
  const text = content.primary.text;
  return (
    <div className="text-with-floating-image slice">
      <div className="text-with-floating-image__image">
        <img src={image.url} alt={image.alt} />
      </div>
      <div className="text-with-floating-image__content rich-text">
        <RichText content={text} />
      </div>
      <div className="text-with-floating-image__clear"></div>
    </div>
  );
};

export default TextWithFloatingImage;
