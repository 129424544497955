import React from "react";
import { Link } from "react-router-dom";
import RichText from "components/atoms/RichText/RichText";
import classNames from "classnames";
import { LayoutContext } from "layout/GenericLayout";
import resolveUrl from "components/helpers/resolveUrl";

const NavSubMenu = ({ item, routes, handleNav, navs }) => {
  const [submenuOpen, setSubmenuOpen] = React.useState(false);
  const [maxHeight, setMaxHeight] = React.useState(0);
  const submenuRef = React.useRef(null);
  const submenuStyles = {
    maxHeight: maxHeight,
  };

  React.useEffect(() => {
    setMaxHeight(submenuOpen ? `${submenuRef.current.scrollHeight}px` : 0);
  }, [submenuOpen]);

  const handleSubmenu = () => {
    setSubmenuOpen(!submenuOpen);
  };

  const handleClick = () => {
    handleNav();
    setSubmenuOpen(false);
  };

  const submenuLinkClass = classNames({
    "mobile-nav__item__link": true,
    parent: true,
    open: submenuOpen,
  });

  return (
    <>
      <div className={submenuLinkClass} onClick={handleSubmenu}>
        <RichText type="text" content={item.label} />
      </div>
      <div
        className={`mobile-nav__submenu`}
        style={{ ...submenuStyles }}
        ref={submenuRef}
      >
        {navs[item.submenu_doc.uid].map((subitem, key) => {
          const url = resolveUrl(subitem.link, routes);
          return (
            <div className="mobile-nav__submenu__item" key={key}>
              <Link
                className="mobile-nav__submenu__item__link"
                to={`${item.link.uid}/${subitem.link.uid}`}
                onClick={handleClick}
              >
                <RichText type="text" content={subitem.label} />
              </Link>
            </div>
          );
        })}
      </div>
    </>
  );
};

const NavItem = ({ item, routes, handleNav, navs }) => {
  const url = resolveUrl(item.link, routes);
  return (
    <li className="mobile-nav__item">
      {item.submenu === true ? (
        <NavSubMenu
          item={item}
          routes={routes}
          handleNav={handleNav}
          navs={navs}
        />
      ) : (
        <Link className="mobile-nav__item__link" to={url} onClick={handleNav}>
          <RichText type="text" content={item.label} />
        </Link>
      )}
    </li>
  );
};

const MobileNav = ({ navs, isOpen, handleNav }) => {
  const { loading, routes } = React.useContext(LayoutContext);
  const navClasses = classNames({
    "mobile-nav": true,
    open: isOpen,
  });
  const mainNav = navs?.main_nav;
  return (
    mainNav && (
      <nav className={navClasses}>
        <ul className="mobile-nav__container">
          {mainNav.map((item, key) => {
            return (
              <NavItem
                key={key}
                item={item}
                routes={routes}
                handleNav={handleNav}
                navs={navs}
              />
            );
          })}
          {/* <li className="mobile-nav__item">
            <a
              className="mobile-nav__item__link"
              href={process.env.USER_AREA_URL}
              target="_blank"
            >
              My personal account
            </a>
          </li> */}
        </ul>

        <div className="mobile-nav__social">
          <a
            className="mobile-nav__social__link"
            href="https://www.facebook.com/PascalePyotPhotographie/"
            target="_blank"
            aria-label="Suivre Pascale Pyot photographe Bressuire en Facebook"
          >
            <i className="icon icon-facebook_header"></i>
          </a>
          <a
            className="mobile-nav__social__link"
            href="https://www.instagram.com/pascale.pyot/"
            target="_blank"
            aria-label="Suivre Pascale Pyot photographe Bressuire en Instagram"
          >
            <i className="icon icon-instagram_header"></i>
          </a>
        </div>
      </nav>
    )
  );
};

export default MobileNav;
