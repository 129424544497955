import getTextFromRichText from "components/helpers/getTextFromRichText";

export default {
  getMeta: data => {
    return {
      meta: {
        title: getTextFromRichText(data.meta_title),
        description: getTextFromRichText(data.meta_description),
        keywords: getTextFromRichText(data.meta_keywords),
      },
    };
  },
};
