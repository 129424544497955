import React, { useState, useEffect, useRef } from "react";
import { Routes, Route, useLocation, matchPath } from "react-router-dom";
import GenericLayout from "./layout/GenericLayout";
import Header from "./layout/Header/Header";
import Footer from "./layout/Footer/Footer";
import resolveRequest from "./routes/resolveRequest";
import Loading from "components/molecules/Loading";
import resolveRoutes from "routes/resolveRoutes";
import getFirstData from "routes/getFirstData";

const isSSR = process.env.SSR_APP === "true";

const App = ({ initialData }) => {
  const [pageData, setPageData] = useState(isSSR ? initialData.pageData : {});

  const [navsData, setNavsData] = useState(isSSR ? initialData.navsData : null);
  const [footerData, setFooterData] = useState(
    isSSR ? initialData.footerData : null
  );
  const [routes, setRoutes] = useState(
    isSSR ? resolveRoutes(initialData.routesData) : null
  );
  const [mounted, setMounted] = useState(isSSR ? false : true);
  const [loading, setLoading] = useState(false);
  const [actualPath, setActualPath] = useState(null);

  const { pathname } = useLocation();

  if (!isSSR) {
    useEffect(() => {
      getFirstData().then((response) => {
        setNavsData(response.navsData);
        setFooterData(response.footerData);
        setRoutes(resolveRoutes(response.routesData));
      });
    }, []);

    useEffect(() => {
      if (!routes) return;
      setLoading(true);
      const { request, type, uid } = resolveRequest(pathname, routes);
      if (typeof type === "undefined") {
        setPageData({});
        setLoading(false);
        setActualPath(pathname);
        return;
      }
      request(type, uid).then((data) => {
        setPageData(data.pageData);
        setLoading(false);
        setActualPath(pathname);
        window.scrollTo(0, 0);
      });
    }, [pathname, routes]);
  }

  if (isSSR) {
    useEffect(() => {
      if (mounted) {
        setLoading(true);
        const { request, type, uid } = resolveRequest(pathname, routes);
        request(type, uid).then((data) => {
          setPageData(data.pageData);
          setLoading(false);
          setActualPath(pathname);
          window.scrollTo(0, 0);
        });
      } else {
        setMounted(true);
        setActualPath(pathname);
      }
    }, [pathname]);
  }

  const header = <Header navs={navsData} />;
  const footer = (
    <Footer
      footerData={footerData}
      nav={navsData?.footer_nav}
      pathname={pathname}
    />
  );
  return (
    <>
      <GenericLayout
        header={header}
        footer={footer}
        layoutData={{
          routes: routes,
          pathname,
          loading,
        }}
      >
        {(loading || actualPath !== pathname) &&
        typeof window !== "undefined" &&
        mounted ? (
          <Loading />
        ) : (
          <>
            <Routes>
              {routes.map((route, key) => {
                const { path, parent, component: Component } = route;

                return (
                  <Route
                    key={key}
                    path={
                      parent
                        ? `${
                            routes.find((route) => route.type === parent).path
                          }/:uid`
                        : path
                    }
                    element={
                      <Component
                        data={pageData}
                        navsData={navsData}
                        pathname={pathname}
                        loading={loading}
                      />
                    }
                  />
                );
              })}
            </Routes>
          </>
        )}
      </GenericLayout>
    </>
  );
};

export default App;
