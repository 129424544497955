import routes from "./routes";
import Home from "../pages/Home/Home";
import Error404 from "../pages/Error404/Error404";

export default urls => {
  const result = [];

  routes.map(item => {
    const obj = {
      path: `/${urls[item.type].url}`,
      ...item,
    };
    result.push(obj);
  });

  result.push({
    path: `/`,
    type: "homepage",
    component: Home,
  });

  result.push({
    path: `*`,
    type: "",
    component: Error404,
  });

  return result;
};
