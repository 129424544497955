import React from "react";
import RichText from "components/atoms/RichText/RichText";
import { Link } from "react-router-dom";
import logo from "assets/img/logo.png";
import resolveUrl from "components/helpers/resolveUrl";
import { LayoutContext } from "layout/GenericLayout";

const Slide = ({ item }) => {
  const { routes } = React.useContext(LayoutContext);

  const url = resolveUrl(item.link, routes);
  return (
    <div className="slider-big-slide">
      <div className="slider-big-slide__logo">
        <div className="slider-big-slide__logo__inner">
          <img src={logo} alt="" className="slider-big-slide__logo__img" />
        </div>
      </div>
      <div className="slider-big-img">
        <picture>
          <source
            srcSet={`${item.image?.url}&q=100&fm=webp`}
            media="(min-width: 1520px)"
          />
          <source
            srcSet={`${item.image.xxl?.url}&q=100&fm=webp`}
            media="(min-width: 1320px)"
          />
          <source
            srcSet={`${item.image.xl?.url}&q=100&fm=webp`}
            media="(min-width: 768px)"
          />
          <img
            src={`${item.image.mobile?.url}&q=100&fm=webp`}
            alt={item.image.alt}
          />
        </picture>
      </div>
      <div className="slider-big-card">
        <div className="slider-big-card-content">
          <p className="body-large slider-big-card-text">
            <RichText type="text" content={item.text} />
          </p>
          <strong className="fancy-title slider-big-card-title">
            <RichText type="text" content={item.title} />
          </strong>
          <span className="slider-big-card-button">
            <Link to={url} className="btn btn-primary">
              <RichText type="text" content={item.label} />
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Slide;
