import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Btn from "components/atoms/Btn/Btn";
import RichText from "components/atoms/RichText/RichText";
import resolveUrl from "components/helpers/resolveUrl";
import { LayoutContext } from "layout/GenericLayout";

const Nav = ({ nav }) => {
  const { routes } = useContext(LayoutContext);
  return (
    nav && (
      <nav className="main-footer__nav__items">
        {nav.map((item, key) => {
          const url = resolveUrl(item.link, routes);
          return (
            <Btn
              key={key}
              hash={item.anchor_hash}
              classes="main-footer__nav__item"
              to={url}
            >
              <RichText type="text" content={item.label} />
            </Btn>
          );
        })}
      </nav>
    )
  );
};

export default Nav;
