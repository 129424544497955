import React, { useContext } from "react";
import RichText from "components/atoms/RichText/RichText";
import Btn from "components/atoms/Btn/Btn";
import { LayoutContext } from "layout/GenericLayout";
import resolveUrl from "components/helpers/resolveUrl";

const CrossSelling = ({ content }) => {
  const { title, description } = content.primary;
  const cards = content.items;
  const { routes } = useContext(LayoutContext);

  return (
    <div className="cross-selling ">
      <div className="cross-selling__inner slice">
        <div className="cross-selling__title">
          <h3 className="h3">
            <RichText type="text" content={title} />
          </h3>
        </div>

        <div className="cross-selling__description">
          <RichText type="text" content={description} />
        </div>
        <div className="cross-selling__cards">
          {cards.map((card, key) => {
            const url = resolveUrl(card.link, routes);
            return (
              <div className="cross-selling__card" key={key}>
                <Btn to={url}>
                  <div className="cross-selling__card__image__container">
                    <div className="cross-selling__card__image">
                      <img src={card.image.cardBig.url} alt="" />
                    </div>
                  </div>
                  <div className="cross-selling__card__title">
                    <h4 className="h3">
                      <RichText type="text" content={card.title} />
                    </h4>
                  </div>
                </Btn>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CrossSelling;
