import client from "./client";

export default {
  loadById: async documentId => {
    const data = await client.getSingle(documentId);
    return data;
  },
  loadAllNavs: async () => {
    const data = await client.getByType("menu");
    return data.results;
  },
  loadByUid: async (documentId, uid) => {
    return await client.getByUID(documentId, uid);
  },
};
