import React from "react";
import SlicesZone from "../../modules/Prismic/SlicesZone";
import GeneralHelmet from "components/molecules/GeneralHelmet/GeneralHelmet";
import HomeFooter from "components/features/HomeFooter";
import NewsletterBlock from "components/organisms/NewsletterBlock";

const Home = ({ data, routes }) => {
  const { slices, meta } = data;

  return (
    <>
      <GeneralHelmet meta={meta} />
      {slices && <SlicesZone slices={slices} routes={routes} />}
      {/* <HomeFooter /> */}
      <NewsletterBlock />
    </>
  );
};

export default Home;
