import React from "react";
import RichText from "components/atoms/RichText/RichText";

const Review = ({ item }) => {
  return (
    <div className="reviews-card">
      <span className="reviews-card-name">
        <RichText content={item.name} />
      </span>
      <span className="reviews-card-category">
        <RichText content={item.category} />
      </span>
      <div className="reviews-card-comment">
        <RichText content={item.comment} />
      </div>
    </div>
  );
};

export default Review;
