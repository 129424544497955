import React from "react";

const Separator = () => {
  return (
    <div className="separator">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="372.758"
        height="36.652"
        viewBox="0 0 372.758 36.652"
      >
        <g
          id="Composant_15_1"
          data-name="Composant 15 – 1"
          transform="translate(0.379 0.326)"
        >
          <line
            id="Ligne_2"
            data-name="Ligne 2"
            y1="36"
            x2="31"
            fill="none"
            stroke="#707070"
            strokeWidth="1"
          />
          <line
            id="Ligne_3"
            data-name="Ligne 3"
            x1="31"
            y1="36"
            transform="translate(31)"
            fill="none"
            stroke="#707070"
            strokeWidth="1"
          />
          <line
            id="Ligne_4"
            data-name="Ligne 4"
            y1="36"
            x2="31"
            transform="translate(62)"
            fill="none"
            stroke="#707070"
            strokeWidth="1"
          />
          <line
            id="Ligne_5"
            data-name="Ligne 5"
            x1="31"
            y1="36"
            transform="translate(93)"
            fill="none"
            stroke="#707070"
            strokeWidth="1"
          />
          <line
            id="Ligne_6"
            data-name="Ligne 6"
            y1="36"
            x2="31"
            transform="translate(124)"
            fill="none"
            stroke="#707070"
            strokeWidth="1"
          />
          <line
            id="Ligne_7"
            data-name="Ligne 7"
            x1="31"
            y1="36"
            transform="translate(155)"
            fill="none"
            stroke="#707070"
            strokeWidth="1"
          />
          <line
            id="Ligne_8"
            data-name="Ligne 8"
            y1="36"
            x2="31"
            transform="translate(186)"
            fill="none"
            stroke="#707070"
            strokeWidth="1"
          />
          <line
            id="Ligne_9"
            data-name="Ligne 9"
            x1="31"
            y1="36"
            transform="translate(217)"
            fill="none"
            stroke="#707070"
            strokeWidth="1"
          />
          <line
            id="Ligne_10"
            data-name="Ligne 10"
            y1="36"
            x2="31"
            transform="translate(248)"
            fill="none"
            stroke="#707070"
            strokeWidth="1"
          />
          <line
            id="Ligne_11"
            data-name="Ligne 11"
            x1="31"
            y1="36"
            transform="translate(279)"
            fill="none"
            stroke="#707070"
            strokeWidth="1"
          />
          <line
            id="Ligne_12"
            data-name="Ligne 12"
            y1="36"
            x2="31"
            transform="translate(310)"
            fill="none"
            stroke="#707070"
            strokeWidth="1"
          />
          <line
            id="Ligne_13"
            data-name="Ligne 13"
            x1="31"
            y1="36"
            transform="translate(341)"
            fill="none"
            stroke="#707070"
            strokeWidth="1"
          />
        </g>
      </svg>
    </div>
  );
};

export default Separator;
