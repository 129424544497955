import React from "react";
import RichText from "../../../../components/atoms/RichText/RichText";

const TitledList = ({ content }) => {
  return (
    <div className="titled-list slice">
      <span className="titled-list-title fancy-title">
        <RichText type="text" content={content.primary.title} />
      </span>
      {content.items.map((item, key) => {
        return <RichText key={key} content={item.item} />;
      })}
    </div>
  );
};

export default TitledList;
