import RichText from "../../../../components/atoms/RichText/RichText";
import React, { useContext } from "react";
import Btn from "components/atoms/Btn/Btn";
import resolveUrl from "components/helpers/resolveUrl";
import { LayoutContext } from "layout/GenericLayout";

const Encarts = ({ content }) => {
  const { routes } = useContext(LayoutContext);
  return (
    <div className="encarts">
      {content.items.map((item, key) => {
        const url = resolveUrl(item.link, routes);
        return (
          <Btn to={url} key={key}>
            <div className={`encarts-card ${item.position}`}>
              <div className="encarts-card-image">
                <img src={item.image.url} alt={item.image.alt} />
              </div>
              <div className="encarts-card-content">
                <h2 className="h3 encarts-card-content-title font-secondary text-center">
                  <RichText type="text" content={item.title} />
                </h2>
                <h3 className="h4 encarts-card-content-description font-secondary text-center">
                  <RichText type="text" content={item.description} />
                </h3>
              </div>
            </div>
          </Btn>
        );
      })}
    </div>
  );
};

export default Encarts;
