import React, { useContext } from "react";
import RichText from "components/atoms/RichText/RichText";
import Btn from "components/atoms/Btn/Btn";
import { Link } from "react-router-dom";
import resolveUrl from "components/helpers/resolveUrl";
import { LayoutContext } from "layout/GenericLayout";

const LandingPage = ({ content }) => {
  console.log(content);
  const { image, title, text, btn_label, btn_link } = content.primary;
  const { items } = content;
  const { routes, pathname } = useContext(LayoutContext);
  const url = resolveUrl(btn_link, routes);
  return (
    <div className="landingpage">
      <div className="landingpage__inner">
        <div className="landingpage__header">
          <div className="landingpage__header__title">
            <h1>
              <RichText type="text" content={title} />
            </h1>
          </div>
          <picture className="landingpage__header__image">
            <source srcSet="" media="(min-width: 780px)" />
            <img src={`${image.url}&q=100&fm=webp`} alt="" />
          </picture>
        </div>
        <div className="landingpage__content">
          <div className="landingpage__content__description">
            <div className="landingpage__content__text">
              <RichText content={text} />
            </div>
            <div className="landingpage__content__btn">
              <Btn to={url} appearance="tertiary">
                {btn_label}
              </Btn>
            </div>
          </div>
          <div className="landingpage__content__links">
            {items.map((item, key) => {
              const { link_image, link, label } = item;

              const url = resolveUrl(link, routes);
              return (
                <div key={key} className="landingpage__link-card">
                  <Link to={url}>
                    <img src={link_image.url} alt="" />
                    <span className="landingpage__link-card__label">
                      {label}
                    </span>
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
