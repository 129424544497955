import React from "react";
import RichText from "components/atoms/RichText/RichText";

const CompanyCard = ({ company }) => {
  const {
    company_name: name,
    company_description: description,
    company_logo: logo,
  } = company;
  return (
    <div className="company-card">
      <div className="company-card__name">
        <RichText type="text" content={name} />
      </div>
      <div className="company-card__description">
        <RichText type="text" content={description} />
      </div>
      <div className="company-card__logo">
        <img src={logo.small.url} alt={logo.alt} />
      </div>
    </div>
  );
};

const CompaniesSlider = ({ content }) => {
  const { title, description } = content.primary;
  const { items: companies } = content;
  return (
    <div className="companies-slider slice">
      <div className="companies-slider__title">
        <h3 className="h3">
          <RichText type="text" content={title} />
        </h3>
      </div>
      <div className="companies-slider__decrption">
        <RichText type="text" content={description} />
      </div>
      <div className="companies-slider__cards">
        {companies.map((company, key) => {
          return <CompanyCard key={key} company={company} />;
        })}
      </div>
    </div>
  );
};

export default CompaniesSlider;
