import React from "react";
import RichText from "components/atoms/RichText/RichText";

const ServiceHeader = ({ content }) => {
  const { service_title: title, cite, author, description } = content.primary;

  return (
    <div className="service-header">
      <h1 className="service-header__title">
        <RichText type="text" content={title} />
      </h1>
      <h2 className="service-header__cite">
        <RichText type="text" content={cite} />
      </h2>
      <div className="service-header__author">
        <RichText type="text" content={author} />
      </div>
      <div className="service-header__description">
        <RichText content={description} />
      </div>
    </div>
  );
};

export default ServiceHeader;
