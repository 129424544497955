import BigTitle from "./BigTitle/BigTitle";
import SliderBig from "./SliderBig/SliderBig";
import ShortBiography from "./ShortBiography/ShortBiography";
import SeoOne from "./SeoOne/SeoOne";
import Reviews from "./Reviews/Reviews";
import Cite from "./Cite/Cite";
import Encarts from "./Encarts/Encarts";
import Carousel from "./Carousel/Carousel";
import TitledList from "./TitledList/TitledList";
import Accordion from "./Accordion/Accordion";
import ImagesGrid from "./ImagesGrid/ImagesGrid";
import TextWithFloatingImage from "./TextWithFloatingImage/TextWithFloatingImage";
import RichTextSlice from "./RichTextSlice/RichTextSlice";
import ImageWithTitleAndDescription from "./ImageWithTitleAndDescription/ImageWithTitleAndDescription";
import PortfolioImages from "./PortfolioImages/PortfolioImages";
import PresentationBiography from "./PresentationBiography";
import ServiceHeader from "./ServiceHeader";
import LinkButton from "./LinkButton";
import BlockVideo from "./BlockVideo";
import CompaniesSlider from "./CompaniesSlider";
import CrossSelling from "./CrossSelling";
import LandingPage from "./LandingPage";

export default {
  big_title: {
    Slice: BigTitle,
  },
  slider_big: {
    Slice: SliderBig,
  },
  short_biography: {
    Slice: ShortBiography,
  },
  seo_one: {
    Slice: SeoOne,
  },
  reviews: {
    Slice: Reviews,
  },
  cite: {
    Slice: Cite,
  },
  encarts: {
    Slice: Encarts,
  },
  carrousel: {
    Slice: Carousel,
  },
  titled_list: {
    Slice: TitledList,
  },
  accordion: {
    Slice: Accordion,
  },
  images_grid: {
    Slice: ImagesGrid,
  },
  text_with_floating_image: {
    Slice: TextWithFloatingImage,
  },
  rich_text: {
    Slice: RichTextSlice,
  },
  image_with_title_and_description: {
    Slice: ImageWithTitleAndDescription,
  },
  portfoilio_images: {
    Slice: PortfolioImages,
  },
  presentation_biography: {
    Slice: PresentationBiography,
  },
  service_title: {
    Slice: ServiceHeader,
  },
  link_button: {
    Slice: LinkButton,
  },
  block_video: {
    Slice: BlockVideo,
  },
  companies_slider: {
    Slice: CompaniesSlider,
  },
  cross_selling: {
    Slice: CrossSelling,
  },
  landingpage: {
    Slice: LandingPage
  }
};
