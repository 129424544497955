import React from "react";
import $ from "jquery";
import Separator from "components/atoms/Separator/Separator";
const Carousel = ({ content }) => {
  const configSlider = ["imgAnt", "imgActual", "imgSig"];

  const listSlider = content.items;

  let len = listSlider.length;
  let sliderState = true;

  const markPoint = k => {
    if (k === -1) k = len - 1;
    if (k === 0) k = len;
    $(".point").removeClass("select");
    $(".point_" + (k - 1)).addClass("select");
  };
  const time = 500;

  const changeSlider = (elem, key) => {
    if ($(elem).parent().hasClass("imgActual") || !sliderState) return;
    sliderState = false;
    if ($(elem).parent().hasClass("imgSig")) {
      if (key === len - 1) key = -1;
      markPoint(key);
      if ($(window).width() >= 600) {
        $(".imgAnt").animate({ left: "-25%", right: "auto" }, time);
        $(".imgActual").animate(
          { height: "22vw", top: "3.5vw", left: "0", width: "25%" },
          time
        );
        $(".imgSig").animate(
          { height: "29vw", right: "25%", top: "0", width: "50%" },
          time
        );
        $(".img_" + (key + 1)).css({ right: "-25%", display: "block" }, time);
        $(".img_" + (key + 1)).animate({ right: "0" }, time);
      } else {
        $(".imgAnt").animate({ left: "-35%", right: "auto" }, time);
        $(".imgActual").animate(
          { height: "30vw", top: "80vw", left: "5%", width: "30%" },
          time
        );
        $(".imgSig").animate(
          { height: "70vw", right: "0", top: "0", width: "100%" },
          time
        );
        $(".img_" + (key + 1)).css({ right: "-35%", display: "block" }, time);
        $(".img_" + (key + 1)).animate({ right: "5%" }, time);
      }
      setTimeout(function () {
        let temp = $(".imgActual");
        temp.removeClass("imgActual");
        $(".imgAnt").addClass("hidden");
        $(".imgAnt").removeClass("imgAnt");
        $(".imgSig").addClass("imgActual");
        $(".imgSig").removeClass("imgSig");
        temp.addClass("imgAnt");
        $(".img_" + (key + 1)).removeClass("hidden");
        $(".img_" + (key + 1)).addClass("imgSig");
        $(".imgAnt").attr("style", "");
        $(".imgSig").attr("style", "");
        $(".imgActual").attr("style", "");
        $(".hidden").attr("style", "");
        sliderState = true;
      }, time);
    } else {
      if (key === 0) key = len;
      markPoint(key);
      if ($(window).width() >= 600) {
        $(".imgSig").animate({ right: "-25%", left: "auto" }, time);
        $(".imgActual").animate(
          {
            height: "22vw",
            top: "3.5vw",
            right: "0",
            left: "75%",
            width: "25%",
          },
          time
        );
        $(".imgAnt").animate(
          { height: "29vw", left: "25%", top: "0", width: "50%" },
          time
        );
        $(".img_" + (key - 1)).css({ left: "-25%", display: "block" }, time);
        $(".img_" + (key - 1)).animate({ left: "0" }, time);
      } else {
        $(".imgSig").animate({ right: "-35%", left: "auto" }, time);
        $(".imgActual").animate(
          {
            height: "30vw",
            top: "80vw",
            right: "auto",
            left: "65%",
            width: "30%",
          },
          time
        );
        $(".imgAnt").animate(
          { height: "70vw", left: "0%", top: "0", width: "100%" },
          time
        );
        $(".img_" + (key - 1)).css({ left: "-35%", display: "block" }, time);
        $(".img_" + (key - 1)).animate({ left: "5%" }, time);
      }

      setTimeout(function () {
        let temp = $(".imgActual");
        temp.removeClass("imgActual");
        $(".imgSig").addClass("hidden");
        $(".imgSig").removeClass("imgSig");
        $(".imgAnt").addClass("imgActual");
        $(".imgAnt").removeClass("imgAnt");
        temp.addClass("imgSig");
        $(".img_" + (key - 1)).removeClass("hidden");
        $(".img_" + (key - 1)).addClass("imgAnt");
        $(".imgAnt").attr("style", "");
        $(".imgSig").attr("style", "");
        $(".imgActual").attr("style", "");
        $(".hidden").attr("style", "");
        sliderState = true;
      }, time);
    }
  };
  return (
    <>
      <section className="carousel">
        <div className="separator">
          <Separator />
        </div>
        <div className="carrousel-title">
          <h2 className="fancy-title text-center">Vos Histoires</h2>
        </div>
        <div className="slider">
          {listSlider.map((item, key) => {
            return (
              <div
                key={key}
                onClick={e => {
                  changeSlider(e.target, key);
                }}
                className={`${configSlider[key]} ${
                  key > 2 ? "hidden" : ""
                } img_${key}`}
              >
                <img src={item.image.url} alt={item.image.alt} />
              </div>
            );
          })}
        </div>
        <div className="clear"></div>
        <div className="points">
          {listSlider.map((item, key) => {
            return (
              <i
                key={key}
                className={`icon icon-point point ${
                  key === 0 ? "active" : ""
                } point_${key}`}
              ></i>
            );
          })}
        </div>
      </section>
    </>
  );
};

export default Carousel;
