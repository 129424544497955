import React from "react";
import * as prismicH from "@prismicio/helpers";
import RichText from "components/atoms/RichText/RichText";

const BlockVideo = ({ content, ident }) => {
  const { block_video_title: title, block_video_description: description } =
    content.primary;

  const videos = content.items;
  return (
    <div className="block-video slice" id={`${content.slice_type}-${ident}`}>
      {title && (
        <h3 className="block-video__title h3">
          <RichText type="text" content={title} />
        </h3>
      )}
      {description && (
        <div className="block-video__description">
          <RichText type="text" content={description} />
        </div>
      )}

      <div className="block-video__cards">
        {videos.map((video, key) => {
          const videoUrl = prismicH.asText(video.video_url);
          const videoID = videoUrl.split("?v=")[1];
          return (
            <div className="block-video__card">
              <div className="block-video__card__title">
                <h4 className="h4">
                  <RichText type="text" content={video.video_title} />
                </h4>
              </div>
              <div className="block-video__inner">
                <iframe
                  className="block-video__video"
                  src={`https://www.youtube.com/embed/${videoID}`}
                  title="SCEA Le Chêne Blanc_3"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default BlockVideo;
