import loadable from "@loadable/component";

export default [
  {
    type: "homepage",
    component: loadable(() => import("pages/Home"), {
      fallback: "loading",
    }),
  },
  {
    type: "apropos",
    component: loadable(() => import("pages/About"), {
      fallback: "loading",
    }),
  },
  {
    type: "prestations",
    child: "prestation",
    component: loadable(() => import("pages/Prestations"), {
      fallback: "loading",
    }),
  },
  {
    type: "prestation",
    parent: "prestations",
    component: loadable(() => import("pages/Prestation"), {
      fallback: "loading",
    }),
  },
  {
    type: "portfolios",
    child: "portfolio",
    component: loadable(() => import("pages/Portfolios"), {
      fallback: "loading",
    }),
  },
  {
    type: "portfolio",
    parent: "portfolios",
    component: loadable(() => import("pages/Portfolio"), {
      fallback: "loading",
    }),
  },
  {
    type: "contact",
    component: loadable(() => import("pages/Contact"), {
      fallback: "loading",
    }),
  },
  {
    type: "custom_page",
    parent: "pages",
    component: loadable(() => import("pages/CustomPage"), {
      fallback: "loading",
    }),
  },

  {
    type: "pages",
    child: "custom_page",
    component: loadable(() => import("pages/CustomPage"), {
      fallback: "loading",
    }),
  },
  {
    type: "supportsphoto",
    component: loadable(() => import("pages/Supportsphoto"), {
      fallback: "loading",
    }),
  },
  {
    type: "legales",
    child: "legal",
    component: loadable(() => import("pages/Legals"), {
      fallback: "loading",
    }),
  },
  {
    type: "legal",
    parent: "legales",
    component: loadable(() => import("pages/Legal"), {
      fallback: "loading",
    }),
  },
];
