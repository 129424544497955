import React, { useRef } from "react";
import Btn from "components/atoms/Btn/Btn";
import Separator from "components/atoms/Separator/Separator";
import SliderOne from "components/organisms/SliderOne/SliderOne";
import Review from "./Review";
import sliderConf from "./sliderConf";
import RichText from "components/atoms/RichText/RichText";
import {
  isMobile,
  isTablet,
} from "components/helpers/mediaQueries/mediaQueries";

const Reviews = ({ content, ident }) => {
  const [slidesPerView, setSlidesPerView] = React.useState(3);

  React.useEffect(() => {
    setSlidesPerView(isMobile ? 1 : isTablet ? 2 : 3);
  }, []);

  return (
    <>
      <div
        className="reviews text-center"
        id={`${content.slice_type}-${ident}`}
      >
        <Separator />
        <span className="fancy-title">Vos petits mots</span>
        <div className="reviews-cards">
          <div className="reviews-cards__button-prev">
            <i className="icon icon-thin-arrow"></i>
          </div>
          <SliderOne
            sliderConf={{
              ...sliderConf,
              slidesPerView: slidesPerView,
              navigation: {
                prevEl: ".reviews-cards__button-prev",
                nextEl: ".reviews-cards__button-next",
              },
            }}
            slider
            items={content.items}
            ItemComponent={Review}
          />
          <div className="reviews-cards__button-next">
            <i className="icon icon-thin-arrow"></i>
          </div>
        </div>
        <Btn
          to={content.primary.button_link.url}
          type="web"
          target="_blank"
          appearance="primary"
        >
          <RichText type="text" content={content.primary.button_label} />
        </Btn>
      </div>
    </>
  );
};

export default Reviews;
