import requests from "../api/requests";

export default (path, routes) => {
  const pathArray = path.replace(/\/+$/, "").split("/").slice(1);
  if (pathArray.length === 0) {
    return {
      request: requests.getByType,
      type: "homepage",
    };
  } else if (pathArray.length === 1) {
    return {
      request: requests.getByType,
      type: routes.find(route => route.path === `/${pathArray[0]}`)?.type,
    };
  } else if (pathArray.length === 2) {
    return {
      request: requests.getByUID,
      type: routes.find(route => route.path === `/${pathArray[0]}`)?.child,
      uid: pathArray[1],
    };
  }
};
